/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

:root {
  .theme-dark {
    --theme-background: #000000;
    --theme-color: #fff;
    --ion-color-secondary: color-mix(in hsl, var(--theme-background), #fff);
  }

  .theme-green {
    --theme-background: #367c2b;
    --theme-color: #fff;
    --ion-color-secondary: color-mix(in hsl, var(--theme-background), #fff);
  }

  .theme-red {
    --theme-background: #9c242e;
    --theme-color: #fff;
    --ion-color-secondary: color-mix(in hsl, var(--theme-background), #fff);
  }

  .theme-blue {
    --theme-background: #0c2c94;
    --theme-color: #fff;
    --ion-color-secondary: color-mix(in hsl, var(--theme-background), #fff);
  }
}

/* Theme variables */
ion-toolbar {
  ion-button,
  ion-back-button {
    --color: var(--theme-color);
  }
}

ion-tab-bar {
  --background: var(--theme-background);
  --color: var(--theme-color);
}

ion-tab-button {
  --color: var(--ion-color-secondary);
  --color-selected: var(--theme-color);
}

ion-toolbar {
  --background: var(--theme-background);
  --color: var(--theme-color);
}

ion-content {
  --background: var(--theme-background) url('/assets/logo.svg') center/40vmin
    no-repeat fixed;
}

ion-item,
ion-searchbar {
  // This is to let the theme come through
  opacity: 0.8;
}

.list-md,
.list-ios {
  background: var(--theme-background);
}

.page-loading {
  display: flex;
  justify-content: center;

  height: 100%;

  ion-spinner {
    margin-top: 16px;
  }
}

.ion-item-description {
  font-size: 0.8em;
}
